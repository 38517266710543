<template>
  <main>
    <img class="banner" src="../../assets/img/new-320.png" alt="乐享理财日">
    <div class="line-title title">
      <i class="line-title__line"></i>
      <h2 class="line-title__text"><span class="title--black-body">“</span>世界乐享理财日<span class="title--black-body">”</span>的设立</h2>
      <i class="line-title__line"></i>
    </div>
    <div class="intro-1__container">
      <p>2018年3月20日，一个财富管理行业铭记的日子，国际专业财富管理协会携手乐享无限集团</p>
      <p>在美丽迷人的香江河畔向全世界郑重宣告了“世界乐享理财日”的诞生！</p>
      <p>“3.20世界乐享理财日”的设立，是国际专业财富管理协会发展宗旨与乐享无限近20年对大众</p>
      <p>的财商普及、教育实践相结合，旨在将科学的财富管理观念传达与全世界华人，让更多华人尽</p>
      <p>早享受乐活、分享、品味的新时代生活。</p>
    </div>

    <div class="line-title title">
      <i class="line-title__line"></i>
      <h2 class="line-title__text">强强联手<span class="title--black-body">，</span>改变未来</h2>
      <i class="line-title__line"></i>
    </div>
    <div class="intro-2__container">
      <p>在海外成熟的金融市场下，正确的财富管理使普通人能够安居乐业，使中产阶级能够分离拼搏，更能使高富阶级财富可以代代相传，这有赖于科学财商观念的普及与广泛应用。</p>
      <p>同海外发达地区相比，中国人的理财观念尚处于萌芽阶段，对待财富的模式皆源于原生家庭及受周边环境的影响。随着人们财富效应的日益壮大，到了不得不面对财富管理的时代，但多数人并没有得到过正确观念的培养也没接触过各类工具的组合运用，无法有效认识风险并采取积极措施对风险进行管理，更无从让财富获得全面、稳定而持续的增长。</p>
      <p>为此，使全世界华人能够通过专业的人生规划，实现财务自由，让家庭的资产得到保障和传承，真正达到“富过三代”，是国际专业财富管理协会与乐享无限近20年一直孜孜不倦的努力与追求！</p>
      <p>最强的倡导者与实践者，两大机构携手创立“世界乐享理财日”，在大中华区，将发挥各自的专业及渠道优势，强强联手，服务所有的华人，并透过线上及线下学习系统，使全世界的华人掌握财富管理知识，让知识指导思维，进而影响行为，最终养成好的习惯。而好习惯形成的性格，可以改变人甚至整个家族的命运！让我们团结一心，携手最强，专业规划，改变未来！</p>
    </div>

    <div class="intro-3__container">
      <div class="line-title title">
        <i class="line-title__line"></i>
        <h2 class="line-title__text">活动回顾</h2>
        <i class="line-title__line"></i>
      </div>
      <div class="intro-3__img-container">
        <img src="../../assets/img/pic_16@2x.png" alt="">
        <img src="../../assets/img/pic_14@2x.png" alt="">
        <img src="../../assets/img/pic_12@2x.png" alt="">
        <img src="../../assets/img/pic_15@2x.jpg" alt="">
        <img src="../../assets/img/pic_13@2x.png" alt="">
        <img src="../../assets/img/pic_11@2x.png" alt="">
      </div>
    </div>
  </main>
</template>

<script>
  export default {
    name: "Event",
    metaInfo: {
      title: '乐享无限官网-品牌活动',
      meta: [
        {
          name: 'keyWords',
          content: '乐享无限品牌活动,320世界乐享理财日,乐享无限有哪些活动,乐享无限官网,乐享无限综合性自我增值平台,智菁通,财务规划',
        },
        {
          name: 'description',
          content: '"3.20世界乐享理财日"是乐享无限的品牌活动，体现了国际专业财富管理协会的发展宗旨, 乐享无限近20年将大众的财商普及与教育实践相结合,旨在将科学的财富管理观念传达与全世界华人,让更多华人尽早享受生活、分享、品味的新时代生活。'
        }
      ]
    },
  }
</script>

<style lang="scss" scoped>
main {
  text-align: center;
}
.banner {
  display: block;
  width: 100%;
  text-align: center;
}
.title {
  margin-top: 80px;
  margin-bottom: 50px;
}
.intro-1__container {
  height: 250px;
  width: 100%;
  background: url("../../assets/img/bg_05@2x.png") no-repeat center center;
  background-size: cover;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  line-height: 27px;
  box-sizing: border-box;
  padding-top: 60px;
}
.intro-2__container {
  width: 1140px;
  margin: 0 auto 80px;
  text-align: left;
  font-size: 16px;
  line-height: 2em;
  text-indent: 2em;
  color: #333333;
}
.intro-3__container {
  background: #fff6ed;
  .title {
    margin-top: 50px;
  }
  padding-bottom: 80px;
}
.intro-3__img-container {
  width: 390px * 3 + 4px * 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  img {
    display: block;
    width: 390px;
    height: 208px;
    margin-bottom: 4px;
  }
  margin: 0 auto;
}
// 带有黄色线的标题

.line-title {
  @include clearfix;
  display: inline-block;
}
.line-title__line {
  height: 4px;
  width: 40px;
  background-color: #f39800;
  display: block;
  float: left;
  margin-top: 18px;
}
.line-title__text {
  float: left;
  font-size: 40px;
  line-height: 40px;
  margin: 0 25px;
}
// 这个标点要用黑体
.title--black-body {
  font-family: "Source Han Sans", '黑体', sans-serif;
}
</style>
